import _ from 'lodash';

import { ViewColumn } from 'common/types/viewColumn';

import { Measure } from '../types';
import { CalculationTypes } from './constants';

export const getCalculationColumns = (measure: Measure, dataSourceColumns: ViewColumn[]): ViewColumn[] => {
  const calculationType = _.get(measure, 'metricConfig.type');
  if (_.isEmpty(calculationType) || _.isEmpty(dataSourceColumns)) {
    return [];
  }

  let columnNames: string[] = [];
  if (calculationType === CalculationTypes.RECENT) {
    columnNames.push(_.get(measure, 'metricConfig.arguments.valueColumn'));
  } else if (calculationType === CalculationTypes.RATE) {
    columnNames.push(_.get(measure, 'metricConfig.arguments.numeratorColumn'));
    columnNames.push(_.get(measure, 'metricConfig.arguments.denominatorColumn'));
  } else {
    columnNames.push(_.get(measure, 'metricConfig.arguments.column'));
  }

  // calculationType may be set even though no column is configured yet.
  columnNames = _.compact(columnNames);

  return _.filter(
    dataSourceColumns,
    (column) => _.includes(columnNames, column.fieldName)
  );
};

export const getDateColumn = (measure: Measure, dataSourceColumns: ViewColumn[]): ViewColumn|undefined => {
  const dateColumnName = measure.metricConfig?.dateColumn;
  if (!dateColumnName) {
    return;
  }

  return _.find(dataSourceColumns, { fieldName: dateColumnName });
};
